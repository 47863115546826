import * as moment from 'moment';
import 'moment-timezone';
import {environment} from '../../environments/environment';

export class Util {
    public static validateEmail(mail: string): boolean {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return (true);
        } else {
            return (false);
        }
    }
    public static validateEmailUned(mail: string): boolean {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.uned\.es$/.test(mail)) {
            console.log('mail valido', mail);
            return (true);
        } else {
            console.log('mail no valido', mail);
            return (false);
        }
    }

    public static validateURL(url: string): boolean {
        console.log('validate string', url);
        /*if (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(url)) {
            return (true);
        } else {
            return (false);
        }*/
        return true;
    }
    /**
     * Calcula el offset dependiendo de la época del año en españa y hace al ajuste considerando que la fecha
     * de entrada (sin offset) se refiere a hora localEspaña
     * @ param date
     */
    public static dateLocalSpainToLocal(date: any): moment.Moment {
        // date = 2021-06-17 11:30:00 ->>  2021-06-17T04:30:00-05:00

        const m = moment.utc(date).subtract(moment(date).tz('Europe/Madrid').utcOffset(), 'm').local();

        // console.warn(date,
        //     '| buscado: ', m.format(),
        //     '| utc: ', moment.utc(date).format(),
        //     '| local: ', moment.utc(date).local().format(),
        //     '| aprox sólo coincide en España: ', moment(date).format());

        return m;
    }

    /**
     * calcula el offset dependiendo de la época del año en españa y hace al ajuste considerando que la fecha
     * de entrada (con offset) se refiere a hora local de otra zona horaria.
     * El parámtro de entrada puede ser una fecha ISOString, Date o Moment que incluyen info TZ
     * @ param date
     */
    public static dateLocalToLocalSpain(date: any): moment.Moment {

        // date =  2021-06-17T04:30:00-05:00 -> 2021-06-17 11:30:00

        // calcula el offset dependiendo de la época del año en españa y hace al ajuste considerando que la fecha
        // de entrada (sin offset) se refiere a hora localEspaña
        const m = moment.utc(date).add(moment.utc(date).tz('Europe/Madrid').utcOffset(), 'm').utc();

        console.error(date,
            '| buscado: ', m.format(),
            '| utc: ', moment.utc(date).format(),
            '| local: ', moment.utc(date).local().format(),
            '| local2: ', moment(date).format());

        return m;
    }


    // /**
    //  * calcula el offset dependiendo de la época del año en españa y hace al ajuste considerando que la fecha
    //  * de entrada (con offset) se refiere a hora local de otra zona horaria.
    //  * El parámtro de entrada puede ser una fecha ISOString, Date o Moment que incluyen info TZ
    //  * Es equivalente a la funcion anterior, supongo que debido a que incluye info de TZ en moment da igual crear el objeto
    //  * con .utc o en el constructor.
    //  * @param date
    //  */
    // public static date2LocalToLocalSpain(date: any): moment.Moment {
    //
    //     // date =  2021-06-17T04:30:00-05:00 -> 2021-06-17 11:30:00
    //
    //     const m = moment(date).add(moment(date).tz('Europe/Madrid').utcOffset(), 'm').utc();
    //
    //     console.warn(date,
    //         '| buscado: ', m.format(),
    //         '| utc: ', moment.utc(date).format(),
    //         '| local: ', moment.utc(date).local().format(),
    //         '| local2: ', moment(date).format());
    //
    //     return m;
    // }

    public static formatDate(date, patternStr): string {
        const monthNames = [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
            'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ];
        const dayOfWeekNames = [
            'Domingo', 'Lunes', 'Martes',
            'Miércoles', 'Jueves', 'Viernes', 'Sábado'
        ];

        if (!patternStr) {
            patternStr = 'M/d/yyyy';
        }
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const second = date.getSeconds();
        const miliseconds = date.getMilliseconds();
        const h = hour % 12;
        const hh = this.twoDigitPad(h);
        const HH = this.twoDigitPad(hour);
        const mm = this.twoDigitPad(minute);
        const ss = this.twoDigitPad(second);
        const aaa = hour < 12 ? 'AM' : 'PM';
        const EEEE = dayOfWeekNames[date.getDay()];
        const EEE = EEEE.substr(0, 3);
        const dd = this.twoDigitPad(day);
        const M = month + 1;
        const MM = this.twoDigitPad(M);
        const MMMM = monthNames[month];
        const MMM = MMMM.substr(0, 3);
        const yyyy = year + '';
        const yy = yyyy.substr(2, 2);
        return patternStr
            .replace('hh', hh).replace('h', h)
            .replace('HH', HH).replace('H', hour)
            .replace('mm', mm).replace('m', minute)
            .replace('ss', ss).replace('s', second)
            .replace('S', miliseconds)
            .replace('dd', dd).replace('d', day)
            .replace('MMMM', MMMM).replace('MMM', MMM).replace('MM', MM).replace('M', M)
            .replace('EEEE', EEEE).replace('EEE', EEE)
            .replace('yyyy', yyyy)
            .replace('yy', yy)
            .replace('aaa', aaa)
            ;
    }

    public static twoDigitPad(num): string {
        return num < 10 ? '0' + num : num;
    }

    public static getQueryParams(query: string): any {
        const hashes = query.slice(query.indexOf('?') + 1).split('&');
        return hashes.reduce((params, hash) => {
            const split = hash.indexOf('=');
            const key = hash.slice(0, split);
            const val = hash.slice(split + 1);
            console.log('key', key);
            console.log('val', val);
            return Object.assign(params, { [key]: decodeURIComponent(val) });
        }, {});
    }
    public static copyClipboard(text: string, ev) {
        ev.stopPropagation();
        ev.preventDefault();
        navigator.clipboard.writeText(text);
    }
    public static copyClipboardRecord(idSala: number, idGrabacion: number, validacion: string, ev) {
        ev.stopPropagation();
        ev.preventDefault();
        navigator.clipboard.writeText(this.getURLRecord(idSala, idGrabacion, validacion));
    }
    public static copyClipboardEmbed(idSala: number, idGrabacion: number, validacion: string, ev, option: number) {
        ev.stopPropagation();
        ev.preventDefault();
        switch (option) {
            case 1: navigator.clipboard.writeText('<iframe src="' + this.getURLRecord(idSala, idGrabacion, validacion) + '" width="1024" height="540" frameborder="0" scrolling="no" allowfullscreen></iframe>');
                    break;
            case 2: navigator.clipboard.writeText('<iframe src="' + this.getURLPlayer(idSala, idGrabacion, validacion) + '" width="1024" height="540" frameborder="0" scrolling="no" allowfullscreen></iframe>');
                    break;
            case 3: navigator.clipboard.writeText('<iframe src="' + this.getURLPlayerWithParams(idSala, idGrabacion, validacion) + '" width="1024" height="540" frameborder="0" scrolling="no" allowfullscreen></iframe>');
                    break;
            default: navigator.clipboard.writeText('<iframe src="' + this.getURLRecord(idSala, idGrabacion, validacion) + '" width="1024" height="540" frameborder="0" scrolling="no" allowfullscreen></iframe>');
                     break;
        }
       // navigator.clipboard.writeText('<iframe src="' + this.getURLRecord(idSala, idGrabacion, validacion) + '" width="1024" height="540" frameborder="0" scrolling="no"></iframe>');
    }
    public static getURLRecord(idSala: number, idGrabacion: number, validacion: string) {
        const validacionUTF = decodeURIComponent(validacion?.replace('&amp;', '&'));
        return environment.portalUrl + `grabacion?ID_Sala=${idSala}&ID_Grabacion=${idGrabacion}&${validacionUTF}`;
    }
    public static getURLPlayer(idSala: number, idGrabacion: number, validacion: string) {
        const validacionUTF = decodeURIComponent(validacion?.replace('&amp;', '&'));
        return environment.portalUrl + `player?ID_Sala=${idSala}&ID_Grabacion=${idGrabacion}&${validacionUTF}`;
    }
    public static getURLPlayerWithParams(idSala: number, idGrabacion: number, validacion: string) {
        const validacionUTF = decodeURIComponent(validacion?.replace('&amp;', '&'));
        // tslint:disable-next-line:max-line-length
        return environment.portalUrl + `player?ID_Sala=${idSala}&ID_Grabacion=${idGrabacion}&${validacionUTF}&descargas=false&visitas=false&subtitulos=false`;
    }
    public static getURLUNEDPlayRecord(idSala: number, idGrabacion: number, uid: string, tipo: number, validacion: string) {
        const validacionUTF = decodeURIComponent(validacion?.replace('&amp;', '&'));
        return environment.unedPlayURL + 'grabacion.php?nameRecord=' + uid + '&record=' + idGrabacion
            + '&tipoRecord=' + tipo + '&arrayhashurl=ID_Sala'
            + encodeURIComponent(`=${idSala}&perfil=_REPRODUCTOR_&username=usuario_reproductor&${validacionUTF}`);
    }

    public static  reemplazarPrimerCaracter(cadena, caracter) {
        if (cadena.charAt(0) === caracter) {
            return cadena.slice(1);
        }
        return cadena;
    }
    public static async URLToBlob(url: string, nombreArchivo: string, tipoMime: string): Promise<string> {
        if ( Util.isMobile() || Util.getBrowserName() !== 'firefox' ){
            return url;
        }

        try {
            const respuesta = await fetch(url);
            if (!respuesta.ok) {
                throw new Error(`Error al obtener el archivo desde la URL: ${url}`);
            }
            const contenidoBlob = await respuesta.blob();
            const archivo = new File([contenidoBlob], nombreArchivo, { type: tipoMime });
            return URL.createObjectURL(archivo);
        } catch (err) {
            // TODO: canalUNED da problema de CORS
            console.warn('Fallback url', err); // Failed to fetch
        }
        return url;
    }

    public static isMobile(){
        return window.navigator.maxTouchPoints > 0;
    }

    public static getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('edg') > -1 && !!(window).hasOwnProperty('chrome'):
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(window).hasOwnProperty('opr'):
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(window).hasOwnProperty('chrome'):
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }
}
