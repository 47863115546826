

import {Par} from './matricula-solr';
import {Role} from './role';

export class User {
  public id: string;
  public email: string;
  public nsrId: number;
  public name: string;
  public lastName: string;
  public date: number;
  public roles: Role[];
  // public state: BehaviorSubject<number>;
  // public state$: Observable<number>;

  public optionsAccessibility: Par[];
  public ATTACHED: string[];
  public showSubtitles: boolean;
  public color = '00533f';

  constructor(nsrId: number = 0, email: string = '', username: string = '', name: string = '', lastName: string = '',
              roles: Role[] = []) {
    this.email = email;
    this.name = name;
    this.lastName = lastName;
    this.nsrId = nsrId;
    this.roles = roles;
    this.setColor();
    this.setOptionsAccesibility();
  }
  // public setToken(src: string): Promise<any> {
  //   // console.log("Setting token: " + src );
  //   return this.storage.set('token', src);
  // }

  // public getToken(): Promise<any> {
  //   return this.storage.get('token');
  // }

  public setName(src: string): void {
    this.name = src;
  }

  public getName(): string {
    return this.name;
  }

  // public setState(src: number): void {
  //   this.state.next(src);
  // }

  // public getState(): number {
  //   return this.state.getValue();
  // }

  public setDate(src: number): void {
    this.date = src;
  }

  public getDate(): number {
    if (this.date) {
      return this.date;
    } else {
      return Date.now();
    }
  }

  public getYear(): number {
    const d = new Date();
    return d.getFullYear();
  }

  public setEmail(src: string): void {
    this.email = src;
  }

  public getEmail(): string {
    return this.email;
  }

  public setId(src: string): void {
    this.id = src;
  }

  public getId(): string {
    return this.id;
  }

  public isAdminUNED(): boolean {
    if (this.roles) {
      return this.roles.find((role) => role?.groupId === 1 && role?.roleId === 1) !== undefined;
    } else {
      return false;
    }
  }
  public isComunicacionUNED(): boolean {
    if (this.roles) {
      return this.roles.find((role) => role?.groupId === 1 && role?.roleId === 9) !== undefined;
    } else {
      return false;
    }
  }
  public isEtiquetadorAccesibilidad(): boolean {
    if (this.roles) {
      return this.roles.find((role) => role?.groupId === 1 && role?.roleId === 8) !== undefined;
    } else {
      return false;
    }
  }

  // public logout(): any {
  //   this.setState(User.NO_AUTHENTICATED);
  //   return this.setToken('');
  // }

  public setOptionsAccesibility() {
    let par;
    this.optionsAccessibility = [];

    par = new Par();
    par.Codigo = '1';
    par.Nombre = 'Priorizar búsquedas con recursos subtitulados';
    par.checked = true;
    this.optionsAccessibility.push(par);
    par = new Par();
    par.Codigo = '2';
    par.Nombre = 'Priorizar búsquedas con recursos con transcripciones';
    par.checked = true;
    this.optionsAccessibility.push(par);
    par = new Par();
    par.Codigo = '3';
    par.Nombre = 'Activar subtitulado en recursos disponibles';
    par.checked = true;
    this.optionsAccessibility.push(par);
  }

  public getOptionsAccessibility() {
    let i: number;
    this.ATTACHED = [];
    for (i = 0; i < this.optionsAccessibility.length; i++) {
      if (this.optionsAccessibility[i].Codigo === '3') {
        // TODO: no lo estamos usando actualmente
        this.showSubtitles = this.optionsAccessibility[i].checked;
      }
      if (this.optionsAccessibility[i].checked === true) {
        switch (this.optionsAccessibility[i].Codigo) {
          case '1':
            this.ATTACHED.push('Subtitulos');
            break;
          case '2':
            this.ATTACHED.push('Transcripcion');
            break;
          default:
            break;
        }
      }
    }
  }

  public getOptionAccessibilityValue(codigo: string) {
    let i: number;
    for (i = 0; i < this.optionsAccessibility.length; i++) {
      if (this.optionsAccessibility[i].Codigo === codigo) {
        return this.optionsAccessibility[i].checked;
      }
    }
  }

  public setOptionAccessibilityValue(codigo: string, value: boolean) {
    console.log('Setting acc value. Code: ' + codigo + ', Value: ' + value);
    let i: number;
    for (i = 0; i < this.optionsAccessibility.length; i++) {
      if (this.optionsAccessibility[i].Codigo === codigo) {
        this.optionsAccessibility[i].checked = value;
      }
    }
  }

  public setOptionAccessibility(codigo: string) {
    let i: number;
    for (i = 0; i < this.optionsAccessibility.length; i++) {
      if (this.optionsAccessibility[i].Codigo === codigo) {
        this.optionsAccessibility[i].checked = !this.optionsAccessibility[i]
            .checked;
      }
    }
  }

  public setColor() {
    const colors = [
      '1a8ab8',
      '69bddf',
      'bece08',
      'cec645',
      'e89f11',
      '1ba84d',
      '897ec8',
      '8f96a1',
      '5200d6',
      '37a368',
      '9432d5',
      'cd00f3',
      '700068',
      'a88e3e',
      '87f7f1',
      'bd7d6f',
      'd4b77a',
      '31488a',
      'f25687',
      '3864ae'
    ];
    let aux = 0;
    for (let i = 0; i < this.name.length; i++) {
      aux = aux + this.name.charCodeAt(i);
    }
    const position = aux % colors.length;
    this.color = colors[position];
  }
}
