import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import {ActivatedRoute, PRIMARY_OUTLET, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {AuthService} from './services/auth.service';
import {environment} from '../environments/environment';
import {UserService} from './services/user.service';
import {UpdateService} from './services/update.service';
import {Angulartics2GoogleTagManager} from 'angulartics2';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public appPages = [];

  constructor(
    private updateService: UpdateService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private platform: Platform,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.angulartics2GoogleTagManager.startTracking();
    router.events.pipe().subscribe(() => {
      const title = this.getTitleFromRoute(route.root);
      this.titleService.setTitle(title);
    });
    this.initializeApp();
    this.authService.getStateObservable().subscribe( state => {
      this.appPages = [
        {
          title: 'Mi portal',
          url: '/inicio',
          icon: 'home',
          ext: false
        },
        {
          title: 'Aulas Virtuales',
          url: state === AuthService.AUTHENTICATED ? '/listado-aulas' : '/autenticacion',
          params: state === AuthService.AUTHENTICATED ? {} : {redirectPage: environment.portalUrl + 'listado-aulas'},
          icon: 'chatbubbles',
          ext: false
        },
        {
          title: 'Mis grabaciones',
          url: state === AuthService.AUTHENTICATED ? '/mis-grabaciones' : '/autenticacion',
          params: state === AuthService.AUTHENTICATED ? {} : {redirectPage: environment.portalUrl + 'mis-grabaciones'},
          icon: 'recording',
          ext: false
        },
        {
          title: 'Cadena Campus',
          url: '/cadena-campus',
          icon: 'play-circle',
          ext: false
        },
        ((!environment.production) || (environment.production && !this.userService.getUserEmail().includes('@alumno.uned.es'))) ?
        {
          title: 'Reserva de eventos',
          url: state === AuthService.AUTHENTICATED ? '/eventos' : '/autenticacion',
          params: state === AuthService.AUTHENTICATED ? {} : {redirectPage: environment.portalUrl + 'eventos'},
          icon: 'calendar',
          ext: false
        } :
        {
              title: 'Reserva de eventos',
              url: '/error',
              icon: 'calendar',
              ext: false
        },
        {
          title: 'Repositorio de Contenidos',
          url: 'http://contenidosdigitales.uned.es/fezUNED/',
          icon: 'albums',
          ext: true
        },
        {
          title: 'GICCU',
          url: 'https://www.intecca.uned.es/giccu/',
          icon: 'create',
          ext: true
        },
        {
          title: 'Aurarev',
          url: '',
          icon: 'people-circle-outline',
          ext: true
        },
        {
          title: 'Redes Sociales',
          url: 'https://comunicacion.intecca.uned.es/',
          icon: 'share-social',
          ext: true
        },
        {
          title: '¿Qué hacemos?',
          url: 'https://www.intecca.uned.es/inteccainfo/',
          icon: 'help',
          ext: true
        },
        {
          title: 'Ayuda',
          url: '/ayuda',
          icon: 'information-circle',
          ext: false
        }
      ];

      if (state === AuthService.NO_AUTHENTICATED) {
        this.authService.doLogin();
      }
    });
    // this.authService.doLogin();
  }

  initializeApp() {
    this.platform.ready().then(() => {
    });
  }
  public getTitleFromRoute(route) {
    if (route.snapshot.data.hasOwnProperty('title')) {
      return route.snapshot.data.title;
    }

    const children: ActivatedRoute[] = route.children;

    // iterate over route children
    for (const child of children) {
      // verify that we have the correct router outlet
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (!child.snapshot.data.hasOwnProperty('title')) {
        return this.getTitleFromRoute(child);
      }

      return child.snapshot.data.title;
    }
    return false;
  }
}
