import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  {
    path: 'inicio', data: { title: 'Página principal del portal AVIP'},
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'iniciar-sesion', data: { title: 'Iniciar sesión'},
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'iniciar-sesion/:redirectPage', data: { title: 'Iniciar sesión'},
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'cerrar-sesion', data: { title: 'Iniciar sesión'},
    loadChildren: () => import('./pages/login-selection/login-selection.module').then( m => m.LoginSelectionPageModule)
  },
  {
    path: 'grabacion', data: { title: 'Grabación'},
    loadChildren: () => import('./pages/recording/recording.module').then( m => m.RecordingPageModule)
  },
  {
    path: 'aula', data: { title: 'Aula virtual'},
    loadChildren: () => import('./pages/room/room.module').then( m => m.RoomPageModule)
  },
  {
    path: 'nueva-aula', data: { title: 'Nueva aula virtual'},
    loadChildren: () => import('./pages/room-create/room-create.module').then(m => m.RoomCreatePageModule)
  },
  {
    path: 'listado-aulas', data: { title: 'Listado de aulas virtuales'},
    loadChildren: () => import('./pages/room-list/room-list.module').then( m => m.RoomListPageModule)
  },
  {
    path: 'autenticacion', data: { title: 'Autenticación'},
    loadChildren: () => import('./pages/login-selection/login-selection.module').then( m => m.LoginSelectionPageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'mis-grabaciones', data: { title: 'Mis grabaciones'},
    loadChildren: () => import('./pages/recording-list/recording-list.module').then( m => m.RecordingListPageModule)
  },
  {
    path: 'cadena-campus', data: { title: 'Cadena Campus '},
    loadChildren: () => import('./pages/cadena-campus-home/cadena-campus-home.module').then( m => m.CadenaCampusHomePageModule)
  },
  {
    path: 'cadena-campus/search', data: { title: 'Cadena Campus Búsqueda'},
    loadChildren: () => import('./pages/cadena-campus-filters/cadena-campus-filters.module').then( m => m.CadenaCampusFiltersPageModule)
  },
 /* {
    path: 'cadena-campus/live', data: { title: 'Cadena Campus Directo'},
    loadChildren: () => import('./pages/cadena-campus-live/cadena-campus-live.module').then( m => m.CadenaCampusLivePageModule)
  },*/
  {
    path: 'cadena-campus/history', data: { title: 'Biblioteca'},
    loadChildren: () => import('./pages/biblioteca/biblioteca.module').then( m => m.BibliotecaPageModule)
  },
  {
    path: 'cadena-campus/milista/:idLista',
    loadChildren: () => import('./pages/milista/milista.module').then( m => m.MilistaPageModule)
  },
  {
    path: 'cadena-campus-listado', data: { title: 'Listado Cadena Campus'},
    loadChildren: () => import('./pages/cadena-campus-list/cadena-campus-list.module').then( m => m.CadenaCampusListPageModule)
  },
  {
    path: 'cadena-campus-reproducir/:uidGrabacion', data: { title: 'Reproductor Cadena Campus'},
    loadChildren: () => import('./pages/cadena-campus-play/cadena-campus-play.module').then( m => m.CadenaCampusPlayPageModule)
  },
  {
    path: 'live', data: { title: 'Reproductor Cadena Campus Directo'},
    loadChildren: () => import('./pages/cadena-campus-live-event/cadena-campus-live-event.module')
        .then( m => m.CadenaCampusLiveEventPageModule)
  },
  {
    path: 'embed-live',
    loadChildren: () => import('./pages/embed-live-event/embed-live-event.module').then( m => m.EmbedLiveEventPageModule)
  },
  {
    path: 'registro', data: { title: 'Registro AVIP'},
    loadChildren: () => import('./pages/login-register/login-register.module').then( m => m.LoginRegisterPageModule)
  },
  {
    path: 'perfil', data: { title: 'Perfil AVIP'},
    loadChildren: () => import('./pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'recording-create', data: { title: 'Formulario para crear grabación'},
    loadChildren: () => import('./pages/recording-create/recording-create.module').then( m => m.RecordingCreatePageModule)
  },
  {
    path: 'recording-update/:id', data: { title: 'Formulario para editar grabación'},
    loadChildren: () => import('./pages/recording-update/recording-update.module').then( m => m.RecordingUpdatePageModule)
  },
  {
    path: 'recording-clonate/:id', data: { title: 'Formulario para duplicar grabación'} ,
    loadChildren: () => import('./pages/recording-clonate/recording-clonate.module').then( m => m.RecordingClonatePageModule)
  },
  {
    path: 'cut-record', data: {title: 'Herramienta de corte de grabación'},
    loadChildren: () => import('./pages/recording-cut/recording-cut.module').then (m => m.CutRecordingPageModule)
  },
  {
    path: 'invitations/:ID_Sala', data: { title: 'Enviar invitaciones'},
    loadChildren: () => import('./pages/invitations/invitations.module').then(m => m.InvitationsPageModule)
  },
  {
    path: 'ayuda/:submenu', data: { title: 'Ayuda'},
    loadChildren: () => import('./pages/contacto/contacto.module').then(m => m.ContactoPageModule)
  },
  {
    path: 'ayuda', data: { title: 'Ayuda'},
    loadChildren: () => import('./pages/contacto/contacto.module').then(m => m.ContactoPageModule)
  },
  {
    path: 'eventos', data: { title: 'Listar reservas técnicas realizadas para eventos'},
    loadChildren: () => import('./pages/event-list/event-list.module').then( m => m.EventListPageModule)
  },
  {
    path: 'crear-evento', data: { title: 'Crear reserva técnica para evento'},
    loadChildren: () => import('./pages/event-create/event-create.module').then( m => m.EventCreatePageModule)
  },
  {
    path: 'evento/:ID_Evento', data: { title: 'Editar reserva técnica para evento'},
    loadChildren: () => import('./pages/event-update/event-update.module').then( m => m.EventUpdatePageModule)
  },
  {
    path: 'evento-agreement/:ID_Evento', data: { title: 'Consentimiento'},
    loadChildren: () => import('./pages/event-agreement/event-agreement.module').then( m => m.EventAgreementPageModule)
  },
  {
    path: 'evento-information/:ID_Evento', data: {title: 'Información reserva de evento'},
    loadChildren: () => import('./pages/event-information/event-information.module').then( m => m.EventInformationPageModule)
  },
  {
    path: 'room-update/:ID_Sala', data: { title: 'Formulario para editar aula virtual'},
    loadChildren: () => import('./pages/room-update/room-update.module').then( m => m.RoomUpdatePageModule)
  },
  {
    path: 'reset-password/:email/:token',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'player',  data: { title: 'Reproductor de video'},
    loadChildren: () => import('./pages/player/player.module').then( m => m.PlayerPageModule)
  },
  {
    path: 'gestion-avisos',
    loadChildren: () => import('./pages/gestion-avisos/gestion-avisos.module').then( m => m.GestionAvisosPageModule)
  },
  {
    path: 'crear-aviso',
    loadChildren: () => import('./pages/crear-aviso/crear-aviso.module').then( m => m.CrearAvisoPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/error/error.module').then( m => m.ErrorPageModule)
  },
  
 /* {
    path: 'cadena-campus-live',
    loadChildren: () => import('./pages/cadena-campus-live/cadena-campus-live.module').then( m => m.CadenaCampusLivePageModule)
  },*/




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
